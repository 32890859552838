#root {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; /* enables “momentum” (smooth) scrolling */
}

span#wave {
	position:relative;
	margin-top:50vh;
	text-align:center;
	width:100px;
	height:100px;
	margin-left: auto;
	margin-right: auto;
}

span.dot {
  display:inline-block;
  width:3px;
  height:3px;
  border-radius:50%;
  margin-right:3px;
  background:#303131;
  animation: wave 1.1s linear infinite;
}

span.dot:nth-child(2) {
  animation-delay: -0.9s;
}

span.dot:nth-child(3) {
  animation-delay: -0.7s;
}

@keyframes wave {
	0%, 60%, 100% {
		transform: initial;
	}

	30% {
		transform: translateY(-3px);
	}
}
