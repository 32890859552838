html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  display: flex;
  position: fixed;
  overflow: hidden;
  background: black;
}

code {
  text-align: left;
  vertical-align: middle;
  border-radius: 0.6em;
  font-size: 0.9em;
  overflow-x: scroll;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  /* -ms-overflow-style: none;  /* IE 10+ */
  /* scrollbar-width: none;  /* Firefox */
}

code::-webkit-scrollbar {
  height: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

pre {
  font-size: 0.9em;
  margin-top: 0.5em;
  margin-bottom: 0px;
  vertical-align: baseline;
}

small {
  font-size: xx-small;
}

a {
  text-decoration: none;
}

a:link {
  color: darkgrey;
}

/* visited link */
a:visited {
  color: lightgrey;
}

/* mouse over link */
a:hover {
  color: black;
}

/* selected link */
a:active {
  color: black;
}

/* TODO : apply to md `` */
grave {
  background: lightgray;
  border-radius: 0.6em;
  font-size: 0.7em;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  padding: 0.3em;
  display: inline;
}
